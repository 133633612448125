import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Not a podcast" />
    <p>
      NOT A PODCAST. NOT A FUND. NOT SOCIALIST WHO DOESNT LIKE CAPITALISTS. WHO DOESN'T LIKE THEMSELVES. NO STRATEGY. NO BENCHMARK.
      ONE DAY YOU'LL THINK LIKE THEM. STOP NOW. YOUR FREEDOM IS THEIR MONEY IS THEIR FREEDOM.
      YOUR COMPENSATION IS YOUR SECURITY IS THEIR SECURITY. "IT'S NOT ZERO SUM" THEY SAY.
      THEY SHARE STUFF ON SNAPCHAT. INTIMATE.
      LEAN STARTUP WITH FAT CAPITAL. INTERNS: WORK HARD AND YOU MAY TAKE OVER THE WORLD ONE DAY. $1,500.
      THE INHERENT VICE OF CAPITALISM IS THE UNEQUAL SHARING OF BLESSINGS.
    </p>
    <p>
      NOT COMING SOON: GERMAN 270.
    </p>
  </Layout>
)

export default IndexPage
